const state = {
  status: "loading",
  error: null,
  data: {},
  customerData: null,
  customer: {},
  invoice: [],
  invData: {},
  order: {},
  newOrderData: {},
};

export default state;
